"use strict";

import.meta.glob(["../img/**", "../icons/**", "../fonts/**"]);

import "@/bootstrap";

import "vue-final-modal/style.css";
import { createApp, defineAsyncComponent } from "vue";
import { createPinia, mapStores } from "pinia";
import { createRouter, createWebHashHistory } from "vue-router";
import { createVfm } from "vue-final-modal";
import { ModalsContainer } from "vue-final-modal";
import { registerScrollSpy } from "vue3-scroll-spy";
import { useCottonBureauStore } from "@/store/index.js";
import { Vue3Mq } from "vue3-mq";
import { VueReCaptcha } from "vue-recaptcha-v3";
import axios from "axios";
import InstantSearch from "vue-instantsearch/vue3/es";
import VueAxios from "vue-axios";
import VueImgix from "@imgix/vue";
import VueSafeTeleport from "vue-safe-teleport";
import PrimeVue from "primevue/config";

const SubNav = defineAsyncComponent(() => import("@/components/sub-nav.vue"));
const SignInPage = defineAsyncComponent(() => import("@/account/sign-in-page.vue"));
const SignUpPage = defineAsyncComponent(() => import("@/account/sign-up-page.vue"));
const ShopSearch = defineAsyncComponent(() => import("@/shop/shop-search.vue"));
const UpdatePayment = defineAsyncComponent(() => import("@/payment/update-payment.vue"));
const SubmitDesignPage = defineAsyncComponent(() => import("@/seller/submit-design-page.vue"));
const PeoplePage = defineAsyncComponent(() => import("@/seller/people-page.vue"));
const PlusPage = defineAsyncComponent(() => import("@/plus/plus-page.vue"));
const HomePage = defineAsyncComponent(() => import("@/home/home-page.vue"));
const CustomPage = defineAsyncComponent(() => import("@/custom/custom-page.vue"));

// Submissions
const CreateProduct = defineAsyncComponent(() => import("@/submission/create-product.vue"));
import VueScrollTo from "vue-scrollto";

// Checkout
const CheckoutView = defineAsyncComponent(() => import("@/checkoutv2/checkout-view.vue"));

// Stores
const StoreBanner = defineAsyncComponent(() => import("@/seller-stores/components/store-banner.vue"));
const StoreFeature = defineAsyncComponent(() => import("@/seller-stores/components/store-feature.vue"));
const StoreSplash = defineAsyncComponent(() => import("@/seller-stores/components/store-splash.vue"));
const ShopView = () => import("@/seller-stores/shop-view.vue"); // routed, do not use defineAsyncComponent
const AboutView = () => import("@/seller-stores/about-view.vue"); // routed, do not use defineAsyncComponent

// Tags
const ProductImageV2 = defineAsyncComponent(() => import("@/product/components/product-image-v2.vue"));

// Account
const AccountHome = defineAsyncComponent(() => import("@/account/account-home.vue"));
const EditProfile = () => import("@/account/edit-profile.vue"); // routed, do not use defineAsyncComponent
const PayoutSettings = () => import("@/account/payout-settings.vue"); // routed, do not use defineAsyncComponent
const PayoutHistory = () => import("@/account/payout-history.vue"); // routed, do not use defineAsyncComponent
const ProductList = () => import("@/account/product-list.vue"); // routed, do not use defineAsyncComponent
const ProductDetailsV2 = () => import("@/account/product-details-v2.vue"); // routed, do not use defineAsyncComponent
const EditProduct = () => import("@/account/edit-product.vue"); // routed, do not use defineAsyncComponent
const PricesServices = () => import("@/account/prices-services.vue"); // routed, do not use defineAsyncComponent
const CampaignDetails = () => import("@/account/campaign-details.vue"); // routed, do not use defineAsyncComponent
const SalesStats = () => import("@/account/sales-stats.vue"); // routed, do not use defineAsyncComponent
const OrderHistory = () => import("@/account/order-history.vue"); // routed, do not use defineAsyncComponent
const OrderDetails = () => import("@/account/order-details.vue"); // routed, do not use defineAsyncComponent
const DiscountsHome = () => import("@/account/discounts-home.vue"); // routed, do not use defineAsyncComponent
const DiscountDetails = () => import("@/account/discount-details.vue"); // routed, do not use defineAsyncComponent
const SettingsHome = () => import("@/account/settings-home.vue"); // routed, do not use defineAsyncComponent
const NotificationsHome = () => import("@/account/notifications-home.vue"); // routed, do not use defineAsyncComponent
const DashboardHome = () => import("@/account/dashboard-home.vue"); // routed, do not use defineAsyncComponent
const TermsOfService = () => import("@/account/terms-of-service.vue"); // routed, do not use defineAsyncComponent

// About Us
const ImgixImage = defineAsyncComponent(() => import("@/components/imgix-image.vue"));

// Patterns
const PatternsColors = defineAsyncComponent(() => import("@/patterns/patterns-colors.vue"));
const PatternsMeter = defineAsyncComponent(() => import("@/patterns/patterns-meter.vue"));
const PatternsSpinner = defineAsyncComponent(() => import("@/patterns/patterns-spinner.vue"));

import CheckoutMixin from "@/mixins/checkout.js";
import FormsMixin from "@/mixins/forms.js";
// import GlobalDataMixin from "@/mixins/global-data.js"; // Not needed
import LayoutMixin from "@/mixins/layout.js";
import PanesMixin from "@/mixins/panes.js";

const CB_DATA = window.CottonBureau;

const pinia = createPinia();

let router = undefined;

import VueDOMPurifyHTML from "vue-dompurify-html"; // used by cottonbureau-product, people-page

// cottonbureau-product BEGIN
const ProductSelector = defineAsyncComponent(() => import("@/product/product-selector.vue"));
const ADD_TO_BAG_URL = `${SITE_URL}cart/ajax/relative-update`;

if (CB_DATA?.entrypoint === "product") {
  router = createRouter({
    history: createWebHashHistory(),
    routes: [
      {
        path: "/:id(\\d+)/:label?",
        name: "variant",
      },
    ],
  });
}
// cottonbureau-product END

// cottonbureau-stores BEGIN
function getCurrentLink(hash) {
  let link = hash.replace(/^#/, "");

  if (link === "/") {
    return "/shop";
  }

  return link;
}

if (CB_DATA?.entrypoint === "store") {
  const DEFAULT_ROUTE = {
    path: "/:pathMatch(.*)*",
    redirect: "/shop",
  };

  const routes = CB_DATA.navLinks.map((link) => {
    switch (link.path) {
      case "/about":
        link.component = AboutView;
        link.name = "about";
        link.props = {
          seller: CB_DATA.seller || {},
          content: CB_DATA.sellerContent || {},
        };
        break;
      // case '/support':
      //   link.component = SupportView;
      //   link.name = 'support';
      //   break;
      case "/shop":
      default:
        link.component = ShopView;
        link.name = "shop";
        link.props = {
          content: CB_DATA.sellerContent || {},
          activeProducts: CB_DATA.products.active,
          inactiveProducts: CB_DATA.products.inactive,
          ondemandProducts: CB_DATA.products.ondemand,
          stockProducts: CB_DATA.products.stock,
        };
        break;
    }

    return link;
  });

  routes.push(DEFAULT_ROUTE);

  router = createRouter({
    linkActiveClass: "SubNav-item--is-active",
    history: createWebHashHistory(),
    routes,
  });
}
// cottonbureau-stores END

// cottonbureau-account START
if (CB_DATA?.entrypoint === "account") {
  const ACCOUNT_ROUTES = CB_DATA.navLinks.map((link) => {
    switch (link.path) {
      case "/dashboard":
        link.component = DashboardHome;
        link.iconName = "accountProfile";
        link.name = "dashboard";
        break;
      case "/products":
        link.component = ProductList;
        link.iconName = "accountProducts";
        link.name = "product-list";
        link.related = ["product-details-v1", "product-details-v2", "campaign-details", "stats"];
        break;
      case "/prices-services":
        link.component = PricesServices;
        link.iconName = "accountProducts";
        link.name = "prices-services";
        link.isSubNav = true;
        break;
      case "/payout-settings":
        link.component = PayoutSettings;
        link.iconName = "accountPayouts";
        link.name = "payout-settings";
        link.props = {
          states: window.G_STATES["US"],
        };
        link.related = ["payout-history"];
        break;
      case "/payout-history":
        link.component = PayoutHistory;
        link.name = "payout-history";
        link.iconName = "accountPayouts";
        link.isSubNav = true;
        break;
      case "/edit-profile":
        link.component = EditProfile;
        link.iconName = "uiUserOpen";
        link.name = "edit-profile";
        link.viewBox = "0 0 24 24";
        break;
      case "/orders":
        link.component = OrderHistory;
        link.iconName = "accountOrder";
        link.name = "orders";
        link.related = ["order-details"];
        break;
      case "/discounts":
        link.component = DiscountsHome;
        link.iconName = "accountPayment";
        link.name = "discounts";
        link.related = ["discount-details"];
        break;
      case "/notifications":
        link.component = NotificationsHome;
        link.iconName = "accountNotifications";
        link.name = "notifications";
        break;
      case "/settings":
        link.component = SettingsHome;
        link.iconName = "accountSettings";
        link.name = "settings";
        break;
    }

    return link;
  });

  // Product Routes
  let productDetailsV2 = {
    component: ProductDetailsV2,
    name: "product-details-v2",
    path: "/products/:productId",
    text: "Product Details",
    iconName: "",
  };

  let editProduct = {
    component: EditProduct,
    name: "edit-product",
    path: "/products/:productId/edit",
    text: "Edit Product",
    iconName: "",
  };

  let campaignDetails = {
    component: CampaignDetails,
    name: "campaign-details",
    path: "/products/:productId/campaigns/:campaignId",
    text: "Campaign Details",
    iconName: "",
  };

  let salesStats = {
    component: SalesStats,
    name: "sales-stats",
    path: "/products/:productId/stats/",
    text: "Sales Stats",
    iconName: "",
  };

  let orderDetails = {
    component: OrderDetails,
    name: "order-details",
    path: "/orders/:orderId",
    text: "Order Details",
    iconName: "",
  };

  let discountDetails = {
    component: DiscountDetails,
    name: "discount-details",
    path: "/discounts/:discountId",
    text: "Discount Details",
    iconName: "",
  };

  let termsOfService = {
    component: TermsOfService,
    name: "terms-of-service",
    path: "/terms-of-service",
    text: "Terms Of Service",
    iconName: "",
  };

  let defaultPath = window.USER.isSeller ? "/dashboard" : "/edit-profile";
  const DEFAULT_ROUTE = {
    path: "/:pathMatch(.*)*",
    redirect: defaultPath,
  };
  const ROUTER_ROUTES = ACCOUNT_ROUTES.concat([productDetailsV2, editProduct, campaignDetails, orderDetails, discountDetails, termsOfService, salesStats, DEFAULT_ROUTE]);

  router = createRouter({
    linkActiveClass: "SubNav-item--is-active",
    history: createWebHashHistory(),
    routes: ROUTER_ROUTES,
    scrollBehavior(to, from, savedPosition) {
      if (savedPosition) {
        return savedPosition;
      } else {
        return { x: 0, y: 0 };
      }
    },
  });

  router.beforeEach((to, from, next) => {
    let handled = false;

    const cottonBureauStore = useCottonBureauStore();

    if (!cottonBureauStore.user.termsAccepted) {
      if (to.path !== "/terms-of-service") {
        if (from.path !== "/terms-of-service") {
          next("/terms-of-service");
          handled = true;
        } else {
          next(false);
          handled = true;
        }
      }
    } else {
      if (to.path === "/terms-of-service") {
        if (from.path === "/terms-of-service") {
          next(defaultPath);
          handled = true;
        } else {
          next(false);
          handled = true;
        }
      }
    }

    if (!handled) {
      next();
    }
  });
}
// cottonbureau-account END

const app = createApp({
  provide() {
    return {
      $_cb_pageLoadData: this.CBData,
      $_cb_stripeKey: this.stripeKey,
    };
  },

  inject: ["updateBreakpoints", "mq"],

  created() {
    this.updateBreakpoints({
      breakpoints: {
        fullscreen: 0,
        desktop: 768,
      },
    });
  },

  components: {
    PatternsColors,
    PatternsMeter,
    PatternsSpinner,
    SubNav,
    SignInPage,
    SignUpPage,
    ShopSearch,
    UpdatePayment,
    SubmitDesignPage,
    CheckoutView,
    ProductSelector,
    PeoplePage,
    CreateProduct,
    ModalsContainer,
    ProductImageV2,

    AccountHome,
    EditProfile,
    PayoutSettings,
    PayoutHistory,
    ProductList,
    ProductDetailsV2,
    EditProduct,
    PricesServices,
    CampaignDetails,
    SalesStats,
    OrderHistory,
    OrderDetails,
    DiscountsHome,
    DiscountDetails,
    SettingsHome,
    NotificationsHome,
    DashboardHome,
    TermsOfService,

    ImgixImage,

    StoreBanner,
    StoreFeature,
    StoreSplash,
    ShopView,
    AboutView,
    PlusPage,
    HomePage,
    CustomPage,
  },

  mixins: [CheckoutMixin, LayoutMixin, PanesMixin, FormsMixin],

  data() {
    return {
      CBData: CB_DATA,

      // cottonbureau-payment and cottonbureau-product and more
      isSubmitting: false,
      message: null,
      stripeKey: window.STRIPE_KEY,

      // cottonbureau-stores BEGIN
      currentLink: getCurrentLink(location.hash),
      content: CB_DATA?.sellerContent || {},
      navLinks: CB_DATA?.navLinks,
      // cottonbureau-stores END

      // cottonbureau-account BEGIN
      accountMessages: CB_DATA?.accountMessages,
      // cottonbureau-account END

      modals: {
        // cottonbureau-product BEGIN
        help: false,
        // cottonbureau-product END
        // cottonbureau-stores BEGIN
        preorder: false,
        stock: false,
        ondemand: false,
        unavailable: false,
        // cottonbureau-stores END
      },

      content: CB_DATA?.sellerContent || {},
      navLinks: CB_DATA?.navLinks,
    };
  },

  computed: {
    ...mapStores(useCottonBureauStore),

    // cottonbureau-product BEGIN
    productActionContainer() {
      return this.mq.desktop ? "#desktopContainer" : "#mobileContainer";
    },
    // cottonbureau-product END

    // cottonbureau-stores BEGIN
    navPortal() {
      return this.mq.desktop ? "#desktopNavContainer" : "#mobileNavContainer";
    },
    // cottonbureau-stores END
  },

  methods: {
    // cottonbureau-payment BEGIN
    onPaymentUpdated(formData) {
      this.isSubmitting = true;

      return this.submitForm("/buy/update-payment", formData, { selfDestruct: false })
        .catch(this.logErrors)
        .finally(() => {
          this.isSubmitting = false;
        });
    },
    // cottonbureau-payment BEGIN

    // cottonbureau-product BEGIN
    addToBag(data) {
      this.isSubmitting = true;

      return this.submitForm(ADD_TO_BAG_URL, data)
        .catch((errorResponse) => {
          let error = this.retrieveErrors(errorResponse);

          this.logErrors(errorResponse);

          return Promise.reject(error);
        })
        .finally(() => {
          this.isSubmitting = false;
        });
    },

    onAddToBag(data) {
      return this.addToBag(data).then(this.toggleCheckoutWithResponse);
    },
    // cottonbureau-product END

    // cottonbureau-stores BEGIN
    onOptionChanged(option) {
      router.push(option);
    },
    // cottonbureau-stores END
  },
});

app.config.compilerOptions = {
  comments: false,
  whitespace: "preserve",
  isCustomElement: (tag) => ["model-viewer", "effect-composer"].includes(tag),
};

// cottonbureau-product BEGIN
app.use(VueDOMPurifyHTML);
// cottonbureau-product END

app.use(pinia);

if (router) {
  app.use(router);
}

app.use(Vue3Mq);
app.use(VueAxios, axios);

app.use(VueImgix, {
  domain: window.RENDER_DOMAIN,
  includeLibraryParam: false,
});

app.use(InstantSearch);

app.use(VueReCaptcha, {
  siteKey: window.RECAPTCHA_KEY,
  loaderOptions: {
    useRecaptchaNet: true,
    autoHideBadge: true,
  },
});

app.use(VueSafeTeleport);
const vfm = createVfm();
app.use(vfm);

app.use(VueScrollTo);

registerScrollSpy(app);

app.use(PrimeVue, {
  unstyled: true,
  pt: {
    datatable: {
      root: {
        class: "DataTable table-component pv-table-component",
      },
      tableContainer: {
        class: "table-component__table-wrapper",
      },
      table: {
        class: "table-component__table",
      },
      thead: {
        class: "table-component__table__head",
      },
      tbody: {
        class: "table-component__table__body",
      },
    },
    column: {
      headerCell: (options) => ({
        class: [
          "table-component__th",
          {
            "table-component__th--sort": options.column.context.sortable,
          },
        ],
      }),
      columnHeaderContent: {
        class: "columnHeaderContent",
      },
      columnTitle: {
        class: "columnTitle",
      },
      sort: {
        class: "sort",
      },
    },
    tab: {
      root: function (options) {
        return {
          class: [
            {
              "is-active": options.context.active,
            },
          ],
        };
      },
    },
  },
});

app.mount("#cottonbureau-app");
